// Adapted from Lucie's 3D logo code <3.

import * as THREE from "three";

import azerImage from "./azer.png";
import toutesImage from "./toutes.png";
import backgroundImage from "./background.png";

const parallaxFactor = 2;

const textureLoader = new THREE.TextureLoader();

function loadTexture(filename: string) {
  const texture = textureLoader.load(filename);
  texture.magFilter = THREE.NearestFilter;
  texture.minFilter = THREE.NearestFilter;
  return texture;
}

function createFlatMaterial(texture: THREE.Texture) {
  let material = new THREE.MeshBasicMaterial({
    map: texture,
    transparent: true,
  });
  material.onBeforeCompile = (shader) =>
    (shader.fragmentShader = shader.fragmentShader.replace(
      "#include <colorspace_fragment>",
      "gl_FragColor = 0.6 * gl_FragColor + 0.4 * linearToOutputTexel(gl_FragColor);",
    ));
  return material;
}

function createGlitchyMaterial(texture: THREE.Texture) {
  // Modified from THREE.ShaderLib.basic.fragmentShader
  const customFragmentShader = `
    uniform vec3 diffuse;
    uniform float time;
    #include <common>
    #include <map_pars_fragment>
    #include <uv_pars_fragment>
    
    /// from https://www.shadertoy.com/view/XtK3W3:
    vec3 mod289(vec3 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
    
    vec2 mod289(vec2 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
    
    vec3 permute(vec3 x) { return mod289(((x * 34.0) + 1.0) * x); }
    
    float snoise(vec2 v) {
        const vec4 C = vec4(0.211324865405187,  // (3.0-sqrt(3.0))/6.0
                            0.366025403784439,  // 0.5*(sqrt(3.0)-1.0)
                            -0.577350269189626, // -1.0 + 2.0 * C.x
                            0.024390243902439); // 1.0 / 41.0
                                                // First corner
        vec2 i = floor(v + dot(v, C.yy));
        vec2 x0 = v - i + dot(i, C.xx);
        
        // Other corners
        vec2 i1;
        // i1.x = step( x0.y, x0.x ); // x0.x > x0.y ? 1.0 : 0.0
        // i1.y = 1.0 - i1.x;
        i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
        // x0 = x0 - 0.0 + 0.0 * C.xx ;
        // x1 = x0 - i1 + 1.0 * C.xx ;
        // x2 = x0 - 1.0 + 2.0 * C.xx ;
        vec4 x12 = x0.xyxy + C.xxzz;
        x12.xy -= i1;
        
        // Permutations
        i = mod289(i); // Avoid truncation effects in permutation
        vec3 p =
            permute(permute(i.y + vec3(0.0, i1.y, 1.0)) + i.x + vec3(0.0, i1.x, 1.0));
        
        vec3 m = max(
            0.5 - vec3(dot(x0, x0), dot(x12.xy, x12.xy), dot(x12.zw, x12.zw)), 0.0);
        m = m * m;
        m = m * m;
        
        // Gradients: 41 points uniformly over a line, mapped onto a diamond.
        // The ring size 17*17 = 289 is close to a multiple of 41 (41*7 = 287)
        
        vec3 x = 2.0 * fract(p * C.www) - 1.0;
        vec3 h = abs(x) - 0.5;
        vec3 ox = floor(x + 0.5);
        vec3 a0 = x - ox;
        
        // Normalise gradients implicitly by scaling m
        // Approximation of: m *= inversesqrt( a0*a0 + h*h );
        m *= 1.79284291400159 - 0.85373472095314 * (a0 * a0 + h * h);
        
        // Compute final noise value at P
        vec3 g;
        g.x = a0.x * x0.x + h.x * x0.y;
        g.yz = a0.yz * x12.xz + h.yz * x12.yw;
        return 130.0 * dot(m, g);
    }

    void main() {
        vec4 diffuseColor = vec4(diffuse, 1.0);

        vec2 sampled_position = vMapUv;

        // HACK: find a better way to only glitch on the bottom text.
        if (vMapUv.y < 0.5) {
            bool should_glitch = snoise(vec2(time, time)) > 0.7 && snoise(vec2(time, 10. * vMapUv.y)) > 0.5;

            if (should_glitch)
                sampled_position.x += snoise(vec2(10000. * round(time * 100.) / 1000. + time / 100., 9953.)) / 10.;
        }

        vec4 sampledDiffuseColor = texture2D(map, sampled_position);
        diffuseColor *= sampledDiffuseColor;
        
        ReflectedLight reflectedLight = ReflectedLight(vec3(0.0), vec3(0.0), vec3(0.0), vec3(0.0));
        reflectedLight.indirectDiffuse += vec3(1.0);
        reflectedLight.indirectDiffuse *= diffuseColor.rgb;
        vec3 outgoingLight = reflectedLight.indirectDiffuse;
        
        gl_FragColor = vec4(outgoingLight, diffuseColor.a);

        // Random color variations
        float noise = max(0.0, snoise(vec2(time, 0)) - 0.3) * (1.0 / 0.7);
        noise = noise + (snoise(vec2(time * 10.0, 5)) - 0.5) * 0.15;
        gl_FragColor.rgb *= 1.0 - (0.15 * noise);
    }
  `;
  const material = new THREE.MeshBasicMaterial({
    map: texture,
    transparent: true,
  });
  material.onBeforeCompile = function (shader) {
    shader.fragmentShader = customFragmentShader;
    shader.uniforms.time = { value: 0 };

    material.userData.shader = shader;
  };
  return material;
}

export default class Azertoutes3DLogo extends HTMLElement {
  private readonly scene = new THREE.Scene();
  private readonly camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
  private readonly backgroudMesh: THREE.Mesh<
    THREE.PlaneGeometry,
    THREE.MeshBasicMaterial
  >;
  private readonly azerMesh: THREE.Mesh<
    THREE.PlaneGeometry,
    THREE.MeshBasicMaterial
  >;
  private readonly toutesMesh: THREE.Mesh<
    THREE.PlaneGeometry,
    THREE.MeshBasicMaterial
  >;
  private readonly renderer: THREE.WebGLRenderer;
  private readonly clock = new THREE.Clock(true);

  constructor() {
    super();

    this.camera.position.y = -0.15;
    this.camera.position.z = 2.5;

    let backgroundGeometry = new THREE.PlaneGeometry(4, 4);
    let frontGeometry = new THREE.PlaneGeometry(
      4 / Math.sqrt(parallaxFactor),
      4 / Math.sqrt(parallaxFactor),
    );

    this.backgroudMesh = new THREE.Mesh(
      backgroundGeometry,
      createFlatMaterial(loadTexture(backgroundImage)),
    );
    this.scene.add(this.backgroudMesh);

    this.azerMesh = new THREE.Mesh(
      frontGeometry,
      createGlitchyMaterial(loadTexture(azerImage)),
    );
    this.scene.add(this.azerMesh);

    this.toutesMesh = new THREE.Mesh(
      frontGeometry,
      createGlitchyMaterial(loadTexture(toutesImage)),
    );
    this.scene.add(this.toutesMesh);

    this.renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true,
    });

    const resizeObserver = new ResizeObserver(this.resizeRenderer.bind(this));
    resizeObserver.observe(this);

    // Pause the animation when the element is not visible.
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.renderer.setAnimationLoop(this.animationLoopCallback.bind(this));
      } else {
        this.renderer.setAnimationLoop(null);
      }
    });
    intersectionObserver.observe(this);

    this.resizeRenderer();

    const style = document.createElement("style");
    style.textContent = `
      :host {
        display: inline-block;
      }

      canvas {
        width: 100%;
        height: 100%;
      }
    `;

    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.appendChild(this.renderer.domElement);
    shadowRoot.appendChild(style);
  }

  private animationLoopCallback() {
    let t = this.clock.getElapsedTime();

    let x = (Math.sin(t + 52) + Math.sin(0.8 * t + 12)) / (1.4 * 45);
    let y = (Math.sin(1.2 * t) + Math.sin(1.6 * t + 99)) / (1.4 * 45);
    let z = (Math.sin(0.5 * t) + Math.sin(2 * t + 9)) / (1.4 * 60);
    let euler = new THREE.Euler(x, y, z);

    this.backgroudMesh.rotation.copy(euler);
    this.azerMesh.rotation.copy(euler);
    this.toutesMesh.rotation.copy(euler);

    const frontOffset = 0.5;
    const actualOffset = frontOffset * parallaxFactor;
    const displacement = new THREE.Vector3(0, 0, actualOffset).applyEuler(
      euler,
    );

    this.azerMesh.position.copy(displacement);
    this.toutesMesh.position.copy(displacement);

    if (this.azerMesh.material.userData.shader !== undefined)
      this.azerMesh.material.userData.shader.uniforms.time.value = t + 20;
    if (this.toutesMesh.material.userData.shader !== undefined)
      this.toutesMesh.material.userData.shader.uniforms.time.value = t;

    this.renderer.render(this.scene, this.camera);
  }

  private resizeRenderer() {
    const width = this.clientWidth;
    const height = this.clientHeight;
    this.renderer.setSize(width, height, false);
  }
}

customElements.define("azertoutes-3d-logo", Azertoutes3DLogo);
